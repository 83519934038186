import axios, { AxiosResponse, AxiosStatic } from "axios";
import { toast } from "react-toastify";

const LOGIN_PATH = "/";
const API_URL = "/api/v1";

export interface ApiResponseObject {
  id: string;
  type: string;
  attributes: any;
  links?: any;
  relationships?: any;
}

export interface ApiSingleResponse {
  data: ApiResponseObject;
}

export interface ApiListResponse {
  data: Array<ApiResponseObject>;
}

export default class Api {
  constructor() {
    axios.defaults.baseURL = API_URL;
  }

  setHeader() {
    axios.defaults.headers.common["X-CSRF-Token"] = this.csrfToken();
  }

  csrfToken(): string {
    const csrfElement = document.querySelector('[name="csrf-token"]');
    if (csrfElement) {
      return csrfElement.getAttribute("content") || "";
    }

    return "";
  }

  axios(): AxiosStatic {
    return axios;
  }

  baseUrl(): string {
    return API_URL;
  }

  handleError(error: any) {
    if (error.response && error.response.status === 401) {
      window.location.href = LOGIN_PATH;
    } else {
      let message = [];

      try {
        if (error.response.status === 403) {
          message.push("You are not able to access this resource");
        } else if (error.response.data.errors) {
          const errors = error.response.data.errors;

          if (Array.isArray(errors)) {
            errors.forEach((err) => {
              message.push(err);
            });
          } else {
            for (let key in errors) {
              message.push(
                `${key.replace("_", " ")} ${errors[key].join(", ")}`,
              );
            }
          }
        } else if (error.response.data.error) {
          message.push(error.response.data.error);
        }
      } catch (e) {
        message = error;
        console.error(
          "[Api] ApiService: Error handling exception response",
          e,
          error,
        );
      }

      toast.error(`An error occurred: ${message.join(", ")}`, {
        position: toast.POSITION.TOP_RIGHT,
      });

      console.error(`[Api] Error`, error);
    }
  }

  query(resource: string, params?: any) {
    return axios
      .get(resource, params)
      .then((r) => r.data)
      .catch(this.handleError);
  }

  get(resource: string, id?: string) {
    let path = `${resource}`;
    if (id) {
      path += `/${id}`;
    }

    return axios
      .get(path)
      .then((r) => r.data)
      .catch(this.handleError);
  }

  post(resource: string, data: any, noMap: boolean = false) {
    let path = `${resource}`;

    return axios
      .post(path, data, {
        headers: {
          "X-CSRF-Token": this.csrfToken(),
        },
      })
      .then((r) => r.data)
      .catch(this.handleError);
  }

  list(resource: string, params = {}) {
    return axios
      .get(`${resource}`, { params: params })
      .then((r) => r.data)
      .catch(this.handleError);
  }

  patch(resource: string, id?: string, changes: any = {}) {
    let path = `${resource}`;
    if (id) {
      path += `/${id}`;
    }

    return axios
      .patch(path, changes, {
        headers: {
          "X-CSRF-Token": this.csrfToken(),
        },
      })
      .then((r) => r.data)
      .catch(this.handleError);
  }

  destroy(resource: string, id?: string, body: any = {}) {
    let path = `${resource}`;
    if (id) {
      path += `/${id}`;
    }

    return axios
      .delete(path, {
        data: body,
        headers: {
          "X-CSRF-Token": this.csrfToken(),
        },
      })
      .catch(this.handleError);
  }

  postForm(resource: string, formData: FormData) {
    return axios
      .post(resource, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-CSRF-Token": this.csrfToken(),
        },
      })
      .catch(this.handleError);
  }

  patchForm(resource: string, id: string, formData: FormData) {
    let path = `${resource}`;
    if (id) {
      path += `/${id}`;
    }

    return axios
      .patch(path, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-CSRF-Token": this.csrfToken(),
        },
      })
      .catch(this.handleError);
  }
}
